import React from 'react';
import './Glossary.css';

const SearchField = ({ term, handleSubmit, onChange, loading, textAreaRef, inputClassName }) => {
  return (
    <div className="w-full flex justify-center">
      <form className="w-full max-w-xl" onSubmit={handleSubmit}>
        <input
          type="text"
          className={`w-full px-5 py-2 rounded-full border bg-background-bright-2 border-gray-300 dark:border-gray-700 dark:bg-background-dark dark:text-text-dark focus:outline-none shadow-sm hover:shadow-md ${inputClassName}`}
          value={term}
          onChange={onChange}
          placeholder="Zu erklärender Begriff"
          ref={textAreaRef}
        />
      </form>
    </div>
  );
};

export default SearchField;
