import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import LeichteSpracheTranslator from '../components/LeichteSpracheTranslator/LeichteSpracheTranslator';
import Feedback from '../components/feedback/Feedback';
import Services from '../components/content/Services';
import BioShort from '../components/content/BioShort';
import { useTranslation } from 'react-i18next';
import StatsOverview from '../components/LeichteSpracheTranslator/statsCard';

const FAQ = lazy(() => import('../components/LeichteSpracheTranslator/FAQ'));

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="App bg-bright dark:bg-background-dark dark:text-text-dark">
      <Helmet>
        {/* <!-- Essential Meta Tags --> */}
        {/* encoding */}
        {/* <meta charSet="UTF-8" /> */}
        <title>Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben</title>
        <meta name="title" content="Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben" />
        <meta name="description" content="Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation." />
        <meta name="keywords" content="Leichte Sprache Übersetzer, Leichte Sprache Online, Texte vereinfachen, Text umschreiben, digitale Barrierefreiheit, einfache Sprache, Textübersetzung, barrierefreie Kommunikation" />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben" />
        <meta property="og:description" content="Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation." />
        <meta property="og:url" content="https://www.leichte-sprache-uebersetzer.de" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/component_screenshot.webp" />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:image:alt" content="Leichte Sprache Online Übersetzer Screenshot" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben" />
        <meta name="twitter:description" content="Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation." />
        <meta name="twitter:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/component_screenshot.webp" />
        <meta name="twitter:image:alt" content="Leichte Sprache Online Übersetzer Screenshot" />

        {/* <!-- Canonical URL --> */}
        <link rel="canonical" href="https://www.leichte-sprache-uebersetzer.de/" />

        {/* <!-- Additional SEO and Accessibility Tags --> */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Lukas Hecker" />
        <meta httpEquiv="Content-Language" content="de" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        
        {/* <!-- Schema.org Markup for HomePage --> */}
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.leichte-sprache-uebersetzer.de/",
            "name": "Leichte Sprache Übersetzer",
            "description": "Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.leichte-sprache-uebersetzer.de/glossar/{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
        `}
        </script>
      </Helmet>
      
      {/* Set a fixed height for the LeichteSpracheTranslator component */}
      <div className='max-w-4xl mx-auto px-4 pb-4 text-center'>
        <h1 className="text-3xl font-bold m-8">{t('home.title')}</h1>
        <p className="text-xl mb-8">
          {t('home.subtitle')}
        </p>
        <p className="text-base" dangerouslySetInnerHTML={{ __html: t('home.paragraph') }}></p>
      </div>
      <div className='mb-24'>
        <LeichteSpracheTranslator />
        <StatsOverview 
          stats={{
            totalWords: "565.281",
            totalSentences: "37.685",
            totalTranslations: "10.609"
          }}
        />
        <Services />
        <div className='flex justify-center -mt-8'>
          <button 
            className="bg-white dark:bg-background-dark text-base border border-gray-300 dark:border-gray-600 shadow-md text-black dark:text-text-dark px-4 py-2 rounded-md hover:shadow-none hover:bg-gray-100 dark:hover:bg-gray-800"
            onClick={() => navigate('/preise')}
          >
            Mehr erfahren
          </button>
        </div>
        <BioShort />
        <div className='flex justify-center -mt-8'>
          <button 
            className="bg-white dark:bg-background-dark text-base border border-gray-300 dark:border-gray-600 shadow-md text-black dark:text-text-dark px-4 py-2 rounded-md hover:shadow-none hover:bg-gray-100 dark:hover:bg-gray-800"
            onClick={() => navigate('/uber-uns')}
          >
            Mehr erfahren
          </button>
        </div>
        <div className='flex justify-center mt-8 content-center'>
          <a href="/login?tab=register" target="_blank" rel="noopener noreferrer" className="flex justify-center w-full md:w-2/3 lg:w-1/2">
            <img src="/imgs/cta_ads/registrieren.png" alt="Aufforderung zum Registrieren" className="h-auto w-full" />
          </a>
        </div>
      </div>
      
      
      <div className="h-px bg-gradient-to-r from-transparent via-gray-400 dark:via-gray-600 to-transparent shadow-md my-16"></div>
      
      <div className="mt-12 max-w-3xl mx-auto">
        <h1 className='text-center text-3xl font-bold mb-6'>FAQ</h1>
        <Suspense fallback={<div style={{ height: '500px' }}>Loading FAQ...</div>}>
          <FAQ />
        </Suspense>
      </div>
      <Feedback />
    </div>
  );
};

export default Home;
